var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"product_details"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"profile-top-bar"},[_c('div',{staticClass:"dashboard-breadcrumb"},[_vm._m(0),_c('div',{staticClass:"text-right",staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.me.customer_name)+" ")])])])]),_c('div',{staticClass:"row row-flex"},[_c('Navbar'),_c('div',{staticClass:"col-md-9"},[[_c('div',[_c('v-table',{staticClass:"table euro-table",attrs:{"data":_vm.customerSummery.orders,"currentPage":_vm.currentPage,"pageSize":10},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"totalPagesChanged":function($event){_vm.totalPages = $event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var displayData = ref.displayData;
return _c('tbody',{},_vm._l((displayData),function(row){return _c('tr',{key:row.guid},[_c('td',[_vm._v(_vm._s(row.order_date))]),_c('td',[_vm._v(_vm._s(row.orderID))]),_c('td',[_vm._v(_vm._s(row.order_items[0].quantity))]),_c('td',[_vm._v(_vm._s(row.total_amount))]),_c('td',{class:row.order_status === 0
                            ? 'cancel'
                            : row.order_status === 1
                            ? 'pending'
                            : row.order_status === 2
                            ? 'confirm'
                            : row.order_status === 3
                            ? 'hold'
                            : row.order_status === 4
                            ? 'shipped'
                            : row.order_status === 5
                            ? 'delivered'
                            : row.order_status === 6
                            ? 'partial'
                            : ''},[_c('span',[_vm._v(_vm._s(row.order_status === 0 ? 'Cancel' : row.order_status === 1 ? 'Pending' : row.order_status === 2 ? 'Confirm' : row.order_status === 3 ? 'Hold' : row.order_status === 4 ? 'Shipped' : row.order_status === 5 ? 'Delivered' : row.order_status === 6 ? 'Partial delivered' : ''))])]),_c('td',{staticStyle:{"text-align":"right"}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.addCart(row)}}},[_vm._v(" Add to Cart ")]),_vm._v("   "),_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.viewDetails(row.id)}}},[_vm._v(" View ")])])])}),0)}}])},[_c('thead',{attrs:{"slot":"head"},slot:"head"},[_c('th',[_vm._v("Order Date")]),_c('th',[_vm._v("Order Id")]),_c('th',[_vm._v("Quantity")]),_c('th',[_vm._v("Total")]),_c('th',[_vm._v("Status")]),_c('th',{staticStyle:{"text-align":"right"}},[_vm._v("Action")])])]),_c('smart-pagination',{attrs:{"currentPage":_vm.currentPage,"totalPages":_vm.totalPages},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)]],2)],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Order From History")])])])])}]

export { render, staticRenderFns }